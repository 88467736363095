<template>
  <div class="main" v-if="status">
    <h1  class="orange">Hello World</h1>
    <p class="c">
      QRL Token Migration was undertaken over 5 years ago.
      <br /><br />
      If you need support, please contact your exchange / provider.
      <br /><br />
      Support may also be available on a discretionary basis at <a href="mailto:support@theqrl.org">support@theqrl.org</a>
      <br /><br />
      <br /><br />
      <a href="https://theqrl.org">
              <!-- Left arrow SVG -->
      <svg width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="#ffa729" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left">
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
        QRL main website</a>
    </p>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        status: true
      };
    },
  };
</script>
<style>
.c {
  text-align: left;
}
  .main {
  position: absolute;
  top: 40%;
  left: 30%;
  margin: -50px 50px 0 -50px;
}
 .orange {
  color: #ffa729;
 }
</style>
